import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'

export const HistoriePageTemplate = ({ image, text }) => (
  <Layout style={{ textAlign: 'center' }}>
    <img
      css={{ maxWidth: '100%', width: 650, borderRadius: 7 }}
      src={image}
      alt=""
    />
    <p css={{ whiteSpace: 'pre-line', textAlign: 'left' }}>{text}</p>
  </Layout>
)

export default ({ data }) => {
  const { text, image } = data.pagesYaml
  return <HistoriePageTemplate image={image} text={text} />
}

export const pageQuery = graphql`
  query HistoriePage($id: String!) {
    pagesYaml(id: { eq: $id }) {
      image
      text
    }
  }
`
